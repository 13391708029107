import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import ColumnLayout from '@components/ColumnLayout';
import Link from '@utility/Link';
import SEO from '@components/seo';
import withFirebase from '../Firebase/withFirebase';

const Verify = ({ firebase }) => {
  const accountData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              _rawVerifyAccount(resolveReferences: { maxDepth: 5 })
            }
          }
        }
      }
    `,
  );
  const [loading, setLoading] = useState(false);
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const { _rawVerifyAccount } = accountData.allSanityAccount.edges[0].node;
  return (
    <ColumnLayout
      offset={false}
      heading={_rawVerifyAccount.heading ? _rawVerifyAccount.heading : ''}
      subheading={
        _rawVerifyAccount.subheading ? _rawVerifyAccount.subheading : ''
      }
      image={_rawVerifyAccount.image && _rawVerifyAccount.image}
    >
      <SEO title="Verify Your Account">
        <meta name="robots" content="noindex" />
      </SEO>
      <Link
        to="/type"
        className="btn-default btn-default--max-width btn-black block text-center"
        aria-label="Browse Products"
      >
        Browse Products
      </Link>
      <p className="type-sans-030 mt-6">
        Don&apos;t see a confirmation email?&nbsp;
        <button
          className={cn(`inline-link type-sans-030`, {
            hidden: emailConfirmation !== '',
          })}
          aria-label="Resend Email"
          type="button"
          disabled={loading}
          onClick={() => {
            setLoading(true);
            firebase
              .firebaseSendEmailVerification()
              .then(() => {
                setEmailConfirmation(
                  'Email sent. Please check your inbox and spam folder.',
                );
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
                setEmailConfirmation(
                  'Whoops, looks like something went wrong. Please reload this page and try again.',
                );
              });
          }}
        >
          Resend Email
        </button>
      </p>
      <p
        className={cn(
          `opacity-0 type-sans-040 tranistion-opacity duration-200`,
          {
            'opacity-100': emailConfirmation !== '',
          },
        )}
      >
        Email sent. Please check your inbox and spam folder.
      </p>
    </ColumnLayout>
  );
};

Verify.propTypes = {
  firebase: PropTypes.shape({
    firebaseSendEmailVerification: PropTypes.func,
  }).isRequired,
};

export default withFirebase(Verify);
