import { REQUIRED_FIELD } from '../../../common';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  serverTimestamp,
  doc,
  collection,
  updateDoc,
  arrayUnion,
  getDoc,
} from 'firebase/firestore';
import FormUpload from '@src/utility/FormUpload';
import { bytesToMB } from '@src/helpers/bytesToMB';
import cn from 'classnames';
import Button from '@src/components/Button';
import Spinner from '../../../static/images/loader-circle.gif';
import withFirebase from '../Firebase/withFirebase';
import { graphql, useStaticQuery } from 'gatsby';
import PortableText from '@src/utility/PortableText';
import FormInput from '@src/utility/FormInput';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import ButtonPlus from '../Project/ButtonPlus';

const allowedTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'application/pdf',
];

const NonTradeDocUpload = ({ authUser, firebase, setHasDocs, hasDocs }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showUploader, setShowUploader] = useState(false);
  const formRef = useRef();

  const profileData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              _rawProfileAccountNonTrade(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    `,
  );

  const { _rawProfileAccountNonTrade } =
    profileData.allSanityAccount.edges[0].node;

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const { errors } = formState;

  const getFileErrorMessage = (errorType) => {
    switch (errorType) {
      case 'type':
        return 'Only PDF/JPG/JPEG/PNG formats are accepted';
      case 'maxFiles':
        return 'You can upload up to 5 files';
      case 'size':
        return 'Each file should not exceed 10MB';
      case 'required':
        return REQUIRED_FIELD;
      default:
        return '';
    }
  };

  const errorHandler = (error) => {
    setIsSubmitting(false);
    window.scrollTo({
      top: formRef.current?.offsetTop || 0,
      behavior: 'smooth',
    });
  };

  const createAttachments = (urls, documentTypes) => {
    let attachments = [];
    urls.map((itemUrl, index) => {
      let attachment = {
        filename: `File-${index + 1}`,
        path: itemUrl,
        contentType: documentTypes[index],
        contentDisposition: 'attachment',
      };
      attachments.push(attachment);
    });

    return attachments;
  };

  const sendEmailToSales = (docUrls, documentTypes, userData) => {
    let data = {
      email: authUser.email,
      name: authUser.first_name + ' ' + authUser.last_name,
      attachments: createAttachments(docUrls, documentTypes),
      ...userData,
    };

    firebase.sendMailOnTradeDocUpload(data).then((res) => {
      console.log('Email sent');
    });
  };

  const onSubmit = async (data) => {
    const documents = [...data.documents];
    setIsSubmitting(true);

    try {
      const urls = await Promise.all(
        documents.map((doc) => {
          return firebase.firebaseUploadTradeDocsFile(
            doc,
            `${authUser.uid}-${doc.name}`,
          );
        }),
      );

      const userRef = doc(collection(firebase.db, 'users'), authUser.uid);

      //to access user_type
      const docSnapshot = await getDoc(userRef);
      const user_type = docSnapshot.exists()
        ? docSnapshot.data().user_type
        : '';

      const userData = {
        documents: arrayUnion(...urls),
        company_url: data.companyUrl,
        company: data.company,
        company_address: data.companyAddress,
        phone: data.phone,
        user_type,
        updated: serverTimestamp(),
      };

      if (data.specificCodes) {
        userData.product_note = data.specificCodes;
      }

      await updateDoc(userRef, userData);

      sendEmailToSales(
        urls,
        documents.map((e) => e.type),
        userData,
      );

      setIsSubmitting(false);
      setShowUploader(false);
      setHasDocs(true);
      reset();
    } catch (error) {
      errorHandler(error);
      console.log(error);
    }
  };

  return (
    <div>
      {hasDocs && (
        <div className="type-sans-330 text-mono-500 lg:mr-24 mb-6 xs:pt-6 mt:mb-0">
          {_rawProfileAccountNonTrade.richText && (
            <div className="richtext-content">
              <PortableText blocks={_rawProfileAccountNonTrade.richText} />
            </div>
          )}
        </div>
      )}

      <Button
        className={cn('type-sans-030 link focus:outline-none w-auto', {
          hidden: showUploader,
        })}
        aria-label={hasDocs ? 'Upload more files' : 'Upload files'}
        onClick={() => setShowUploader(true)}
      >
        {hasDocs ? 'Upload more files' : 'Upload files'}
      </Button>

      {showUploader && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          ref={formRef}
          className="md:w-[60%]"
        >
          <FormInput
            label="Company"
            {...register('company', { required: true })}
            placeholder="Company"
            errorMessage={errors.company && REQUIRED_FIELD}
          />
          <FormInput
            label="Company Address"
            {...register('companyAddress', { required: true })}
            placeholder="Company Address"
            errorMessage={errors.companyAddress && REQUIRED_FIELD}
          />
          <FormInput
            label="Company Website"
            {...register('companyUrl', { required: true })}
            placeholder="Company Website"
            errorMessage={errors.companyUrl && REQUIRED_FIELD}
          />
          <FormInput
            label="Phone number"
            {...register('phone', { required: true })}
            placeholder="Enter your phone number"
            errorMessage={errors.phone && REQUIRED_FIELD}
          />
          <FormUpload
            isOptional={undefined}
            className={undefined}
            {...register('documents', {
              required: true,
              onChange: (e) => {
                setValue(
                  'documents',
                  [...(getValues('documents') || []), ...e.target.files].filter(
                    (value, index, self) =>
                      index === self.findIndex((t) => t.name === value.name),
                  ),
                );
              },
              validate: {
                maxFiles: (value) => {
                  const files = [...value];

                  if (files.length > 5) {
                    return false;
                  }
                  return true;
                },
                type: (value) => {
                  const files = [...value];

                  const fileTypes = files.map((file) => file.type);

                  if (fileTypes.some((type) => !allowedTypes.includes(type))) {
                    setValue(
                      'documents',
                      files.filter((file) => allowedTypes.includes(file.type)),
                    );
                    return false;
                  }

                  return true;
                },
                size: (value) => {
                  const files = [...value];

                  if (files.some((file) => bytesToMB(file.size) > 10)) {
                    return false;
                  }

                  return true;
                },
              },
            })}
            label="Document Upload"
            // isCompactLabel="true"
            uploadMessage="Please upload your valid EIN and/or Resale Certificates"
            type="file"
            multiple
            accept=".pdf, image/jpeg, image/png"
            errorMessage={
              errors.documents
                ? errors.documents.type === 'maxFiles'
                  ? [...watch('documents')]?.length > 5
                    ? getFileErrorMessage(errors.documents.type)
                    : null
                  : getFileErrorMessage(errors.documents.type)
                : null
            }
          />

          {/* Display uploaded documents */}

          {watch('documents')?.length ? (
            <div>
              {[...watch('documents')].map((file, index) => (
                <div
                  key={index}
                  className="text-mono-500 block break-all text-left mb-1 last:mb-0"
                >
                  {file.name}{' '}
                  <button
                    className={cn('btn-icon', 'opacity-50')}
                    style={{
                      backgroundImage: `url('/images/icons/icon-close.svg')`,
                    }}
                    aria-label="Close document uploader"
                    onClick={(e) => {
                      e.preventDefault();

                      const currentFiles = [...getValues('documents')];
                      const newFiles = currentFiles.filter(
                        (currentFile) => currentFile.name !== file.name,
                      );

                      setValue('documents', newFiles);
                      trigger('documents');
                    }}
                  />
                </div>
              ))}
            </div>
          ) : null}

          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton className="mt-8">
                  <ButtonPlus>Specific products to quote</ButtonPlus>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="project-card__fields-inner">
                <div className="mt-4">
                  {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                  <FormInput
                    {...register('specificCodes')}
                    placeholder="Add product codes you want to quote"
                    defaultValue={''}
                  />
                  {/* </form> */}
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          {/* Submit */}
          <Button
            type="submit"
            className={cn(
              'btn-default btn-black btn-lg mt-2 btn-interaction',
              {
                'btn-black-disabled': !formState.isValid,
              },
              { 'is-loading': isSubmitting },
            )}
            aria-label="Submit"
            disabled={isSubmitting}
          >
            <>
              <span>Submit</span>
              <span>
                <img
                  src={Spinner}
                  alt="Sending"
                  width="16px"
                  height="16px"
                  className="ml-6 absolute left-0 top-0"
                />
                Sending
              </span>
            </>
          </Button>
        </form>
      )}
    </div>
  );
};

export default withFirebase(NonTradeDocUpload);
