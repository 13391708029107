import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import FormError from '@utility/FormError';
import FormInput from '@utility/FormInput';
import Button from '@components/Button';

const DeleteConfirmation = ({ onSuccess, text }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitError, throwSubmitError] = useState(true);
  const { register, handleSubmit, formState } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const { errors } = formState;

  const onSubmit = () => {
    onSuccess().catch((error) => {
      throwSubmitError(error);
    });
  };
  return (
    <div className="relative mt-8 print:hidden">
      <Button
        className={cn('type-sans-030 link focus:outline-none w-auto', {
          'opacity-0 pointer-events-none': showConfirmation,
        })}
        aria-label={text}
        onClick={() => setShowConfirmation(true)}
      >
        {text}
      </Button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={cn(
          'opacity-0 pointer-events-none flex justify-between w-full transition-opacity duration-200',
          {
            'opacity-100 pointer-events-auto': showConfirmation,
          },
          {
            absolute: !submitError?.code,
          },
        )}
      >
        <div className="w-1/2">
          <FormInput
            {...register('delete', {
              validate: (value) => value.toLowerCase() === 'delete',
            })}
            label="Confirm Delete"
            placeholder="Enter the word 'Delete' to confirm"
            errorMessage={
              errors.delete && 'That text does not match the word delete.'
            }
          />
        </div>
        <div className="ml-8 w-1/2 mt-10">
          <Button
            type="submit"
            className={cn(
              'type-sans-040 focus:outline-none mr-4 text-mono-400',
              {
                'text-mono-600': formState.isValid,
              },
            )}
            aria-label="Confirm"
          >
            Confirm
          </Button>
          <Button
            type="submit"
            className="type-sans-040 focus:outline-none"
            onClick={() => setShowConfirmation(false)}
            aria-label="Cancel"
          >
            Cancel
          </Button>
        </div>
      </form>
      {submitError?.code && (
        <FormError key={submitError.code}>
          <p>{submitError.message}</p>
        </FormError>
      )}
    </div>
  );
};

DeleteConfirmation.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default DeleteConfirmation;
