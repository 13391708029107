import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import ColumnLayout from '@components/ColumnLayout';
import Link from '@utility/Link';
import SEO from '@components/seo';
import withFirebase from '../Firebase/withFirebase';
import Button from '@src/components/Button';
import Spinner from '../../../static/images/loader-circle.gif';

const TIMER_SECONDS = 180;

const VerifyInbox = ({ firebase }) => {
  const accountData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              _rawVerifyAccount(resolveReferences: { maxDepth: 5 })
            }
          }
        }
      }
    `,
  );
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState();
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [codeDigits, setCodeDigits] = useState(Array(6).fill(''));
  const [codeVerified, setCodeVerified] = useState(false);
  const { _rawVerifyAccount } = accountData.allSanityAccount.edges[0].node;
  const inputRefs = Array.from({ length: 6 }, () => useRef(null));
  const intervalRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (codeVerified) return;

    if (!window.history?.state?.email && !window.history?.state?.uui) {
      setCodeVerified(true);
      navigate('/account/login', { replace: true });
    }
    // Set focus on the first input field when the component mounts
    inputRefs[0].current.focus();

    runTimer();

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalRef.current);
  }, []);

  const runTimer = () => {
    clearInterval(intervalRef.current);
    setTimer(TIMER_SECONDS);

    // @ts-ignore
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          // Timer reached zero, perform any necessary actions here
          setErrorMessage('Code expired. Please resend code below.');
          clearInterval(intervalRef.current);
          return 0;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    if (codeDigits.join('').length === 6) {
      submitCode();
    }
  }, [codeDigits]);

  const handleInputChange = (index, value) => {
    if (value.length > 1) {
      return;
    }
    setErrorMessage('');
    const newValues = [...codeDigits];
    newValues[index] = value;
    setCodeDigits(newValues);

    // Move to the next input field when a value is entered
    if (newValues[index] && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    // Move to the previous input field when the value is cleared/backspace
    if (!value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleKeyUp = (index, event) => {
    const key = event.key;
    const value = event.target.value;

    // Handle key events, navigation, and validation
    if (key === 'ArrowRight' && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    } else if (key === 'ArrowLeft' && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (key === 'Backspace' && index > 0) {
      return;
    } else if (!['ArrowRight', 'Backspace', 'ArrowLeft'].includes(key)) {
      const newCodeDigits = [...codeDigits];

      if (codeDigits[index] !== '' && index < 5) {
        newCodeDigits[index + 1] = key;
        setCodeDigits(newCodeDigits);
        inputRefs[index + 1].current.focus();
        event.stopPropagation();
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    //reset error message
    setErrorMessage('');

    const pasteData = event.clipboardData.getData('text/plain');

    // Update the codeDigits state based on the pasted data
    const newCodeDigits = Array.from(pasteData).slice(0, 6);
    console.log(newCodeDigits);
    setCodeDigits(newCodeDigits);

    // Distribute each digit to the corresponding input field
    newCodeDigits.forEach((digit, index) => {
      if (inputRefs[index] && inputRefs[index].current) {
        inputRefs[index].current.value = digit;
      }
    });

    inputRefs[inputRefs.length - 1].current.focus();
  };

  const handleKeyDown = (index, event) => {
    // Move to the previous input field on backspace when the field is already empty
  };

  const onCodeVerificationDone = () => {
    setCodeVerified(true);

    const currentState = window.history.state;
    //user auto login.
    autoLogin(currentState.email, currentState.password);
    // remove the window history state keys, email and uuid
    delete currentState.email;
    delete currentState.uuid;

    window.history.replaceState(currentState, null, null);
  };

  const autoLogin = (email, password) => {
    firebase
      .firebaseSignInWithEmailAndPassword(email, password)
      .then((signedInUser) =>
        // * refresh token in case user is already logged in
        // // occurs in sign up flow if user revisits same
        // // tab on email verification
        signedInUser.getIdToken(true),
      )
      .then(() => firebase.auth.currentUser.reload())
      .catch((error) => {
        console.error(error);
      });
  };

  const submitCode = () => {
    if (codeDigits.every((value) => value !== '')) {
      const combinedValue = codeDigits.join('');
      console.log('Combined value:', combinedValue);
      setLoading(true);
      firebase
        .verifyEmailCode({
          code: combinedValue,
          email: window.history.state.email,
          uuid: window.history.state.uuid,
        })
        .then(async (res) => {
          setLoading(false);
          let { expired, verified } = res.data;
          if (expired) {
            setErrorMessage('Code expired. Please resend code below.');
          } else if (!verified) {
            setErrorMessage(
              `Hmmm... Doesn't seem right. Please correct or get a new code.`,
            );
          } else {
            //=> successfully verified code
            setErrorMessage('');
            onCodeVerificationDone();
          }
          console.log(res);
        });
    } else {
      console.log('Please fill in all fields before submitting.');
    }
  };

  const onResendClick = () => {
    setLoading(true);
    firebase
      .sendVerficationCodeToMail({
        email: window.history.state.email,
      })
      .then(() => {
        setEmailConfirmation(
          'Email sent. Please check your inbox and spam folder.',
        );
        runTimer();
        // Reset emailConfirmation after 10 seconds
        setTimeout(() => {
          setEmailConfirmation('');
        }, 10000);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setEmailConfirmation(
          'Whoops, looks like something went wrong. Please reload this page and try again.',
        );
      });
  };

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  return (
    <ColumnLayout
      offset={false}
      heading={
        codeVerified ? 'Your profile has been created!' : 'Verify your inbox'
      }
      subheading={
        codeVerified
          ? ''
          : 'Enter the 6 digit code that we sent to your email inbox'
      }
      image={_rawVerifyAccount.image && _rawVerifyAccount.image}
    >
      <SEO title="Verify Your Inbox">
        <meta name="robots" content="noindex" />
      </SEO>

      {codeVerified && (
        <>
          <Link
            to="/type"
            className="btn-default btn-default--max-width btn-black block text-center"
            aria-label="Browse Products"
            noAfter={true}
          >
            Browse Products
          </Link>

          <p className="type-sans-030 mt-10">
            <Link
              to="/account/projects"
              styled={false}
              className="inline-link"
              aria-label="Create A Project"
            >
              Create A Project
            </Link>
          </p>
        </>
      )}

      {!codeVerified && (
        <>
          <div>
            <div onPaste={handlePaste} className="flex">
              {inputRefs.map((inputRef, index) => (
                <input
                  key={index}
                  ref={inputRef}
                  type="number"
                  className={`border-b border-b-black w-8 mr-2 text-left text-[50px] outline-none !rounded-none ${
                    errorMessage ? 'border-b-red' : ''
                  }`}
                  value={codeDigits[index]}
                  max={9}
                  min={0}
                  pattern="[0-9]*"
                  onKeyUp={(e) => handleKeyUp(index, e)}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                />
              ))}
            </div>
            {errorMessage && (
              <p className="text-red type-sans-030 mt-3 absolute">
                {errorMessage}
              </p>
            )}
          </div>

          <Button
            onClick={submitCode}
            className={cn(
              'btn-default btn-black btn-lg uppercase !w-72 mt-14 btn-interaction',
              {
                'btn-black-disabled': codeDigits.some((value) => value === ''),
              },
              { 'is-loading': loading },
            )}
            aria-label="Verify"
            disabled={loading}
          >
            <>
              <span>Verify</span>
              <span>
                <img
                  src={Spinner}
                  alt="Loading"
                  width="16px"
                  height="16px"
                  className="ml-6 absolute left-0 top-0"
                />
                Loading
              </span>
            </>
          </Button>

          <p className="type-sans-030 mt-6">
            Don&apos;t see a verification code?&nbsp;
            <Link
              onClick={() => onResendClick()}
              styled={false}
              className="inline-link"
              aria-label="Resend code"
            >
              Resend code
            </Link>
          </p>
          <p
            className={cn(
              `type-sans-040 mb-4 absolute mt-2 transition-opacity duration-300 ease-in-out`,
              {
                'opacity-0': emailConfirmation === '',
                'opacity-100': emailConfirmation !== '',
              },
            )}
          >
            {emailConfirmation}
          </p>

          <p className={`type-sans-030 mt-10 ${timer === 0 ? 'text-red' : ''}`}>
            <img
              src={`/images/icons/term${timer === 0 ? '-red' : ''}.svg`}
              className="inline align-middle h-4"
            />{' '}
            <span className="ml-1">{`${minutes}:${
              seconds < 10 ? '0' : ''
            }${seconds}`}</span>
          </p>
        </>
      )}
    </ColumnLayout>
  );
};

VerifyInbox.propTypes = {
  // firebase: PropTypes.shape({
  //   firebaseSendEmailVerification: PropTypes.func,
  // }).isRequired,
};

export default withFirebase(VerifyInbox);
