import SEO from '@components/seo';
import ProjectsContext from '@context/ProjectsContext';
import PortableText from '@src/utility/PortableText';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import * as styles from '../Profile/styles.module.scss';

const Projects = ({ authUser }) => {
  const { reps, repsLoading } = useContext(ProjectsContext);
  const accountData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              _rawProjectsAccountTrade(resolveReferences: { maxDepth: 10 })
              _rawProjectsAccountNonTrade(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    `,
  );
  const { _rawProjectsAccountTrade, _rawProjectsAccountNonTrade } =
    accountData.allSanityAccount.edges[0].node;

  const showRep =
    !repsLoading &&
    reps !== null &&
    reps.length &&
    authUser.user_trade === true;

  return (
    <div className={showRep ? styles.showRep : ''}>
      <SEO title="My Projects">
        <meta name="robots" content="noindex" />
      </SEO>
      <h1 className={styles.heading}> Welcome {authUser.first_name}</h1>
      {showRep ? (
        <h2 className="type-sans-730 text-mono-400">
          Your RBW sales rep is <span>{reps[0]['name']}</span>
        </h2>
      ) : (
        ''
      )}
      {(_rawProjectsAccountTrade || _rawProjectsAccountNonTrade) && (
        <PortableText
          blocks={
            authUser?.user_trade
              ? _rawProjectsAccountTrade.richText
              : _rawProjectsAccountNonTrade.richText
          }
        />
      )}
    </div>
  );
};

Projects.propTypes = {
  authUser: PropTypes.shape({
    first_name: PropTypes.string,
  }).isRequired,
};

export default Projects;
