import Loader from '@components/Loader';
import AuthUserContext from '@context/AuthUserContext';
import ScrollInView from '@utility/ScrollInView/index';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import AccountNavigation from '../account/AccountNavigation';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { authUser, authHasConnected } = useContext(AuthUserContext);

  const routeUser = () => {
    if (
      !authUser?.emailVerified &&
      !localStorage.getItem('hasCreatedRBWAccount') &&
      location.pathname !== `/account/create`
    ) {
      // If user already is logged in but still has
      // not verified their account and not created their account
      navigate('/account/create', { replace: true });

      return null;
    }
    // If returning user navigate to login
    if (!authUser?.emailVerified && location.pathname !== `/account/login`) {
      // If user already is logged in but still has
      // not verified their account
      navigate('/account/login', { replace: true });

      return null;
    }
    // Return component if authUser exists
    if (!!authUser) {
      localStorage.setItem('previousAccountRoute', location.pathname);
      return (
        <AccountNavigation authUser={authUser}>
          <ScrollInView animateType="fadeIn" key={location.pathname}>
            <Component {...rest} authUser={authUser} />
          </ScrollInView>
        </AccountNavigation>
      );
    }
    return null;
  };
  // make sure an established connection to firebase auth has occured before routing
  return <div>{!authHasConnected ? <Loader fullPage /> : routeUser()}</div>;
};
PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

PrivateRoute.defaultProps = {
  location: PropTypes.shape({
    pathname: '',
  }),
};

export default PrivateRoute;
