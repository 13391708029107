import AuthUserContext from '@context/AuthUserContext';
import FirebaseContext from '@context/FirebaseContext';
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  Timestamp,
  where,
} from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
/* eslint-disable no-console */
const useQuotes = (projectId, project) => {
  const firebase = useContext(FirebaseContext);
  const [loading, setLoading] = useState(true);
  const [quotes, setQuotes] = useState([]);
  const { authUser } = useContext(AuthUserContext);
  useEffect(() => {
    const unsubscribe = authUser
      ? onSnapshot(
          query(
            collection(firebase.db, `projects/${projectId}/quotes`),
            where('roles.owners', 'array-contains', `${authUser.uid}`),
            orderBy('created', 'desc'),
          ),
          (snapshot) => {
            if (snapshot.size) {
              setLoading(false);
              const quotesArray = [];
              snapshot.forEach((doc) =>
                quotesArray.push({ ...doc.data(), id: doc.id }),
              );
              setQuotes(quotesArray);
            } else {
              setLoading(false);
              setQuotes([]);
            }
          },
          (error) => {
            console.log('Error querying quotes:', error);
          },
        )
      : () => null;
    return () => {
      unsubscribe();
      setQuotes([]);
      setLoading(true);
    };
  }, [authUser, firebase.db, projectId]);

  const pushQuoteToNetsuite = (data) => {
    firebase
      .addNetsuiteQuoteRecord(data)
      .then((result) => {
        // Read result of the Cloud Function.
        // console.log(
        //   'Quote record successfully added to quotes document and netsuite',
        //   result,
        // );
      })
      .catch((error) => {
        // Getting the Error details.
        // console.log('Quote record error:', error.code);
      });
  };

  const createQuote = (onSuccess) => {
    // add() will auto generate a quote id
    addDoc(collection(firebase.db, `projects/${projectId}/quotes`), {
      status: 'requested',
      roles: {
        owners: [firebase.auth.currentUser.uid],
      },
      products: { ...project?.products },
      created: serverTimestamp(),
    })
      .then((docRef) => {
        onSuccess();
        let pushProject = {...project};
        let updatedProducts = {};
        Object.keys(pushProject.products).forEach(k=>{
          let keModified = k.replace("0_1", "0.1");
          updatedProducts[keModified] = project.products[k];         
        })
        pushProject.products = updatedProducts;
        pushQuoteToNetsuite({
          ...pushProject,
          quote: {
            id: docRef.id,
            status: 'requested',
            created: Timestamp.now(),
          },
        });
      })
      .catch((error) => {
        console.error('Error writing quote: ', error);
      });
  };

  return { createQuote, quotes, loading };
};

export default useQuotes;
