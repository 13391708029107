import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@components/Loader';
import * as styles from './styles';
import { graphql, useStaticQuery } from 'gatsby';
import PortableText from '@src/utility/PortableText';

/* eslint-disable no-console */
const Quotes = React.forwardRef(({ quotes, loading }, ref) => {
  const noteData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              _rawRfqNote(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    `,
  );
  const { _rawRfqNote } = noteData.allSanityAccount.edges[0].node;

  const padNumber = (number) => {
    if (number < 10) {
      return `0${number}`;
    }
    return number;
  };

  const renderTime = (isoDate) => {
    const date = new Date(isoDate * 1000);
    const h = padNumber(date.getHours());
    const m = padNumber(date.getMinutes());
    const s = padNumber(date.getSeconds());
    return `${h}:${m}:${s}`;
  };

  const renderDate = (isoDate) => {
    const date = new Date(isoDate * 1000);
    const year = date.getFullYear();
    const month = padNumber(date.getMonth() + 1);
    const dt = padNumber(date.getDate());
    return `${month} / ${dt} /  ${year}`;
  };

  const renderQuoteListDateTime = (quote, time) => {
    if (quote) {
      return (
        <>
          <li>{quote[time]?.seconds && renderTime(quote[time].seconds)}</li>
          <li>{quote[time]?.seconds && renderDate(quote[time].seconds)}</li>
        </>
      );
    }
  };

  const renderQuoteList = () => {
    if (quotes.length) {
      return (
        <div>
          <h2 className="type-sans-630 mt-16 mb-6 print:mt-0" ref={ref}>
            Project Quote Requests
          </h2>
          <div className={styles.quotes}>
            <div className={`${styles.quotesItem} ${styles.quotesItemHeading}`}>
              <h3>Time</h3>
              <h3>Date</h3>
              <h3>Status</h3>
            </div>
            {quotes.map((quote) => (
              <ul key={quote?.id} className={styles.quotesItem}>
                {quote?.status === 'complete' && quote?.updated
                  ? renderQuoteListDateTime(quote, 'updated')
                  : renderQuoteListDateTime(quote, 'created')}
                <li>
                  {quote?.status === 'complete' ? (
                    <span style={{ color: '#285942' }}>Quote sent</span>
                  ) : (
                    'Pending'
                  )}
                </li>
              </ul>
            ))}
          </div>
          <p className="type-sans-330 text-mono-500 mr-12">
            <PortableText blocks={_rawRfqNote.richText} />
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="print:break-before-page print:w-4/5 print:ml-auto">
      {loading ? <Loader /> : renderQuoteList()}
    </div>
  );
});

Quotes.displayName = 'Quotes';

Quotes.propTypes = {
  loading: PropTypes.bool.isRequired,
  quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Quotes;
