import SEO from '@components/seo';
import ProjectsContext from '@context/ProjectsContext';
import Link from '@utility/Link';
import PortableText from '@utility/PortableText';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import DeleteConfirmation from '../DeleteConfirmation';
import withFirebase from '../Firebase/withFirebase';
import SalesRep from '../SalesRep';
import * as styles from './styles';
import NonTradeDocUpload from './uploadDocs';

const Profile = ({ authUser, firebase }) => {
  const { reps, repsLoading } = useContext(ProjectsContext);
  const [hasDocs, setHasDocs] = useState(false);

  const profileData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              _rawProfileAccount(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    `,
  );
  const { _rawProfileAccount } = profileData.allSanityAccount.edges[0].node;
  
  useEffect(() => {
    setHasDocs(authUser.documents?.length > 0);
  }, [])

  return (
    <div>
      <SEO title="My Profile">
        <meta name="robots" content="noindex" />
      </SEO>
      <div>
        <h1 className={styles.heading}>Profile</h1>
        <div className={styles.listRow}>
          <div className={styles.listFirstItem}>
            <h2 className={styles.listLabel}>First Name</h2>
            <p className={styles.listAttribute}>{authUser.first_name}</p>
          </div>
          <div className={styles.listLastItem}>
            <h2 className={styles.listLabel}>Last Name</h2>
            <p className={styles.listAttribute}>{authUser.last_name}</p>
          </div>
        </div>
        <div className={styles.listRow}>
          <div className={styles.listFirstItem}>
            <h2 className={styles.listLabel}>Email</h2>
            <p className={styles.listAttribute}>{authUser.email}</p>
          </div>
          <div className={styles.listLastItem}>
            <h2 className={styles.listLabel}>Password</h2>
            <Link to="/account/reset-password" className="type-sans-030" aria-label="Change Password">
              Change Password
            </Link>
          </div>
        </div>
        <div className={styles.listRow}>
          <div className={styles.listFirstItem}>
            <h2 className={styles.listLabel}>Company</h2>
            <p className={styles.listAttribute}>{authUser.company}</p>
          </div>
          <div className={styles.listLastItem}>
            <h2 className={styles.listLabel}>Role</h2>
            <p className={styles.listAttribute}>{authUser.user_type}</p>
          </div>
        </div>
      </div>
      <div>
        <div className={`type-sans-330 text-mono-500 lg:mr-24 mb-6 md:pt-6 mt:mb-0 ${!authUser.user_trade && hasDocs ? 'hidden' : ''}`}>
          {authUser.user_trade === true ? (
            <p className="font-normal">You are a verified RBW Trade user.</p>
          ) : hasDocs ? <></> : (
            <>
              {_rawProfileAccount.richText && (
                <div className="richtext-content">
                  <PortableText blocks={_rawProfileAccount.richText} />
                </div>
              )}
            </>
          )}
        </div>

        {!authUser.user_trade && <div className='md:mt-8'>
          <NonTradeDocUpload authUser={authUser} setHasDocs={(val) => setHasDocs(val)} hasDocs={hasDocs}/>
        </div>}
        <SalesRep reps={reps} repsLoading={repsLoading} />
        <div className="md:mt-16">
          <DeleteConfirmation
            onSuccess={firebase.firebaseDeleteUser}
            text="Delete Profile"
          />
        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  authUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    user_type: PropTypes.string,
    company: PropTypes.string,
    user_trade: PropTypes.bool,
    documents: PropTypes.array
  }).isRequired,
  firebase: PropTypes.shape({
    firebaseDeleteUser: PropTypes.func,
    firebaseAddMessage: PropTypes.func,
  }).isRequired,
};

export default withFirebase(Profile);
