import React from 'react';
import PropTypes from 'prop-types';

import Logo from '@components/Logo';

const PrintHeading = () => (
  <div className="hidden print:flex print:w-full print:mb-24">
    <div className="w-1/3 flex">
      <Logo />
    </div>
  </div>
);

export default PrintHeading;
