import Button from '@src/components/Button';
import Popup from '@src/components/Popup';
import Link from '@src/utility/Link';
import React, { useState } from 'react';

const CustomProductsAlert = ({ isOpen, onClose }) => {
  return (
    <Popup isOpen={isOpen} onClose={onClose} widthClass="!max-w-[700px] !h-auto">
      <div>
        <div className="project-card__remove">
          <Button
            className="project-card__remove-button"
            onClick={() => onClose()}
            aria-label="Remove product"
          />
        </div>
        <h1 className="type-sans-530 md:type-sans-630 mb-8 text-left md:text-center">
          Your items have been added to cart except for the ones with custom
          finish.
        </h1>
        <p className='mb-8 text-left md:text-center'>
          If you'd like to know the pricing and estimate ship date of those
          products,<br></br> please submit a request for quote, or contact sales@rbw.com
        </p>
        <div className="text-left md:text-center">
          <Link
            to="https://support.rbw.com/kb/guide/en/requesting-a-quote-on-rbw-com-oDdP5xkHix/Steps/548191"
            styled={false}
            className="inline-link type-sans-330"
            aria-label="How to request a quote?"
          >
            How to request a quote?
          </Link>
        </div>
      </div>
    </Popup>
  );
};

export default CustomProductsAlert;
