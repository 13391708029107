import Popup from '@components/Popup';
import Button from '@src/components/Button';
import FormError from '@src/utility/FormError';
import PortableText from '@src/utility/PortableText';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import * as styles from './styles.module.scss';

const RFQPopup = ({ isOpen, onClose, requestQuoteFunction, variables }) => {
  const accountData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              rfqPopupTitle
              _rawRfqPopupBody(resolveReferences: { maxDepth: 10 })
              _rawRfqPopupCheckbox(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    `,
  );
  const { rfqPopupTitle, _rawRfqPopupBody, _rawRfqPopupCheckbox } =
    accountData.allSanityAccount.edges[0].node;

  const [isTermAccepted, setIsTermAccepted] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (isTermAccepted && showError) {
      setShowError(false);
    }
  }, [isTermAccepted, showError]);

  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <span className="hidden">
        <img src={'/images/icons/icon-checkmark.svg'} alt="Logo" />
      </span>
      <div className={styles.rfqPopup}>
        <h1 className="type-sans-630 md:type-sans-730 mb-8">{rfqPopupTitle}</h1>
        <PortableText
          blocks={_rawRfqPopupBody.richText}
          variables={variables}
        />
        <div className="my-10 flex gap-4 items-start text-mono-500">
          <input
            type="checkbox"
            id="terms"
            checked={isTermAccepted}
            onChange={(e) => setIsTermAccepted(e.target.checked)}
            className={styles.checkbox}
          />
          <label htmlFor="terms">
            <PortableText blocks={_rawRfqPopupCheckbox.richText} />
          </label>
        </div>
        {showError && (
          <FormError>
            Please check the box above before proceeding with request for quote
          </FormError>
        )}
        <div className="flex gap-8 mt-10 md:gap-12 flex-col items-start md:flex-row">
          <Button
            className="type-sans-030 link focus:outline-none w-auto focus:active:shadow-none"
            aria-label="Send my request for quote"
            onClick={() => {
              if (isTermAccepted) {
                requestQuoteFunction();
                onClose();
              } else {
                setShowError(true);
              }
            }}
          >
            Send my request for quote
          </Button>
          <Button
            className="type-sans-030 link focus:outline-none w-auto "
            onClick={onClose}
            aria-label="I'm fine for now"
          >
            I'm fine for now
          </Button>
        </div>
      </div>
    </Popup>
  );
};

RFQPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  requestQuoteFunction: PropTypes.func.isRequired,
};

export default RFQPopup;
