import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import LoadingGif from '../../../static/images/loader-rbw.gif';

const Loader = ({ fullPage }) => (
  <div
    className={cn(
      'w-full h-full flex justify-center flex-col items-center pointer-events-none',
      {
        'w-screen h-screen absolute inset-0': fullPage,
      },
    )}
  >
    <div>
      <img
        src={LoadingGif}
        alt="Loading..."
        width="72px"
        style={fullPage ? { transform: 'translateY(-10px)' } : {}}
      />
    </div>
  </div>
);

Loader.defaultProps = {
  fullPage: false,
};
Loader.propTypes = {
  fullPage: PropTypes.bool,
};

export default Loader;
