import React from 'react';
import PropTypes from 'prop-types';
import Button from '@components/Button';
import * as styles from './styles';

const ButtonPlus = ({ children, ...rest }) => (
  <Button
    className={styles.button}
    iconLeft
    icon="plus-s"
    iconClassName={styles.buttonPlus}
    {...rest}
  >
    {children}
  </Button>
);

ButtonPlus.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonPlus;
