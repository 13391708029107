import React from 'react';
import { Router, Redirect } from '@reach/router';
import PropTypes from 'prop-types';
import PrivateRoute from '@utility/PrivateRoute';
import Profile from '../account/Profile';
import Projects from '../account/Projects';
import Project from '../account/Project';
import Login from '../account/Login';
import Create from '../account/Create';
import Verify from '../account/Verify';
import ResetPassword from '../account/ResetPassword';
import FindRepresentative from '@src/account/FindRepresentative';
import VerifyInbox from '@src/account/VerifyInbox';

const Account = ({ location }) => {
  return (
    <div className="wrapper">
      <div className="lg:mt-12 mb-32" style={{ minHeight: '600px' }}>
        <Router basepath="/account" primary={false}>
          <PrivateRoute
            path="/profile"
            component={Profile}
            location={location}
          />
          <PrivateRoute
            path="/projects"
            component={Projects}
            location={location}
          />
          <PrivateRoute
            path="/projects/:projectId"
            component={Project}
            location={location}
          />
          <PrivateRoute
            path="/find-your-rep"
            component={FindRepresentative}
            location={location}
          />
          <Create path="/create" />
          {/* commented as the new VerifyInbox with OTP input is used now */}
          {/* <Verify path="/verify" /> */} 
          <VerifyInbox path="/verify-inbox" />
          <Login path="/login" />
          <ResetPassword path="/reset-password" />
          <Redirect noThrow from="/" to="/account/login" />
        </Router>
      </div>
    </div>
  );
};

Account.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Account;
