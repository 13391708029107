import React, { useContext } from 'react';
import Button from '@components/Button';
import Link from '@utility/Link';
import PropTypes from 'prop-types';
import withFirebase from './Firebase/withFirebase';
import ProjectsContext from '@context/ProjectsContext';

const DISPLAY_DEALER_MOD_ROLES = [
  'Interior Designer / Architect',
  'Lighting Designer',
  'Electrical Distributor / Purchasing Agent',
]

const AccountNavigation = ({ children, firebase, authUser }) => {
  const { projects } = useContext(ProjectsContext);

  const renderProjects = () => {
    if (projects && projects.length) {
      return (
        <>
          {projects.map((proj) => (
            <li key={proj.id}>
              <Link
                aria-label={`Go to ${proj.name}`}
                to={`/account/projects/${proj.id}`}
                activeClassName="text-mono-400"
                styled={false}
                className="type-sans-430 block truncate hover:text-mono-400 focus:text-mono-400 transition-colors duration-300 ease-in-out mr-6"
              >
                {proj.name}
              </Link>
            </li>
          ))}
        </>
      );
    }
  };

  return (
    <div className="row print:block print:mx-0">
      <div className="col-12 sm:col-4 lg:col-3 print:hidden">
        <h1 className="type-upper-240 mb-20 mt-2 lg:mt-8 hidden sm:block">
          Account
        </h1>
        <nav>
          <ul>
            <li>
              <Link
                to="/account/projects"
                activeClassName="text-mono-400"
                underline={false}
                className="type-sans-530 inline-block"
                aria-label="See All Projects"
              >
                Projects
              </Link>
            </li>
            <ul className="mb-2 sm:mb-4">{renderProjects()}</ul>
            {DISPLAY_DEALER_MOD_ROLES.includes(authUser.user_type) && <li className="sm:mb-4">
              <Link
                activeClassName="text-mono-400"
                to="/account/find-your-rep"
                underline={false}
                className="type-sans-530 inline-block"
                aria-label="Find your rep"
              >
                Find your rep
              </Link>
            </li>}
            <li className="sm:mb-4">
              <Link
                activeClassName="text-mono-400"
                to="/account/profile"
                underline={false}
                className="type-sans-530 inline-block"
                aria-label="Profile"
              >
                Profile
              </Link>
            </li>
            <li className="no-underline-link inline-block mb-10">
              <Button
                className="type-sans-530 focus:outline-none"
                onClick={firebase.firebaseSignOut}
                aria-label="Logout"
              >
                Logout
              </Button>
            </li>
          </ul>
        </nav>
      </div>
      <div className="col-12 sm:col-8 lg:offset-1 lg:col-6 print:col-12 print:offset-0">
        {children}
      </div>
    </div>
  );
};

AccountNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  firebase: PropTypes.shape({
    firebaseSignOut: PropTypes.func,
  }).isRequired,
};

export default withFirebase(AccountNavigation);
