import Button from '@components/Button';
import ProjectsContext from '@context/ProjectsContext';
import AuthUserContext from '@src/context/AuthUserContext';
import FormInput from '@utility/FormInput';
import FormSelect from '@utility/FormSelect';
import cn from 'classnames';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { UsaStates } from 'usa-states';
import {
  EMAIL_ERROR_MESSAGE,
  PROJECT_APPLICATION_TYPES,
  REQUIRED_FIELD,
} from '../../../common';
import * as styles from '../Profile/styles.module.scss';
import countries from './countries.json';

const ProjectEdit = ({ projectId, setEditMode, project }) => {
  const { updateProject } = useContext(ProjectsContext);
  const { authUser } = useContext(AuthUserContext);

  const states = new UsaStates();

  const {
    register,
    handleSubmit,
    watch,
    setValue,

    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const watchUsaSelect = watch('country');

  useEffect(() => {
    // Initialize values using setValue, especially for fields with conditions
    setValue(
      'country',
      project?.address?.country ? encodeURI(project?.address?.country) : 'US',
    );
    setValue(
      'state',
      project?.address?.state ? encodeURI(project?.address?.state) : '',
    );
  }, [project]);

  useEffect(() => {
    // Ensure state gets reset if the country is not US
    if (watchUsaSelect && watchUsaSelect !== encodeURI('US')) {
      setValue('state', '');
    }
  }, [watchUsaSelect]);

  const onSubmit = (data) => {
    const formattedData = {
      application_type: decodeURI(data.applicationType),
      address: {
        city: data.city,
        country: decodeURI(data.country),
        address: data.address,
        zip: data.zip,
        state: decodeURI(data.state),
        phone: data.phone,
      },
      alternativeEmail: data.alternativeEmail,
      specifier: data.specifier,
    };

    const projectData = {
      application_type: project?.application_type,
      address: { ...project?.address },
      specifier: project?.specifier,
    };

    if (!isEqual(formattedData, projectData)) {
      updateProject(projectId, formattedData);
    }

    setEditMode(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.listForm}>
      <div className={styles.listRow}>
        <div className={styles.listFirstItem}>
          <FormSelect
            {...register('applicationType', { required: true })}
            label="Application Type"
            placeholder="Select"
            defaultValue={
              project?.application_type
                ? encodeURI(project?.application_type)
                : ''
            }
            errorMessage={errors.applicationType && REQUIRED_FIELD}
            className={cn({
              'text-mono-600': watch(
                'applicationType',
                project?.application_type,
              ),
            })}
          >
            <>
              {PROJECT_APPLICATION_TYPES.map((type) => (
                <option key={encodeURI(type)} value={encodeURI(type)}>
                  {type}
                </option>
              ))}
            </>
          </FormSelect>
        </div>
        <div className={styles.listLastItem}>
          <FormSelect
            {...register('country', { required: true })}
            label="Project Country"
            placeholder="Select project country"
            defaultValue={
              project?.address?.country
                ? encodeURI(project?.address.country)
                : encodeURI('US')
            }
            // onChange={() => {
            //   if (watchUsaSelect !== encodeURI('US')) {
            //     setValue('state', '');
            //   }
            // }}
            errorMessage={errors.country && REQUIRED_FIELD}
            className={cn({
              'text-mono-600': watch(
                'country',
                project?.address?.country
                  ? encodeURI(project?.address.country)
                  : encodeURI('US'),
              ),
            })}
          >
            {countries.map((country) => (
              <option
                key={encodeURI(country.code)}
                value={encodeURI(country.code)}
              >
                {country.name}
              </option>
            ))}
          </FormSelect>
        </div>
      </div>
      <div className={styles.listRow}>
        <div className={styles.listFirstItem}>
          <FormSelect
            {...register('state', {
              required: watchUsaSelect === encodeURI('US'),
            })}
            label="Project State"
            placeholder="Select project state"
            disabled={watchUsaSelect !== encodeURI('US')}
            defaultValue={
              project?.address?.state ? encodeURI(project?.address.state) : ''
            }
            errorMessage={errors.state && REQUIRED_FIELD}
            disabledCondition={watchUsaSelect !== encodeURI('US')}
            className={cn({
              'text-mono-600': watch(
                'state',
                project?.address?.state && encodeURI(project?.address.state),
              ),
            })}
          >
            {states.states.map((state) => (
              <option key={state.abbreviation} value={encodeURI(state.name)}>
                {state.name}
              </option>
            ))}
          </FormSelect>
        </div>
        <div className={styles.listLastItem}>
          <FormInput
            label="Project City"
            {...register('city', { required: true })}
            placeholder="Enter project city"
            defaultValue={project?.address?.city ? project?.address.city : ''}
            errorMessage={errors.city && REQUIRED_FIELD}
          />
        </div>
      </div>
      <div className={styles.listRow}>
        <div className={styles.listFirstItem}>
          <FormInput
            label="Zip Code"
            {...register('zip', { required: true })}
            placeholder="Enter project ZIP code"
            defaultValue={project?.address?.zip ? project?.address.zip : ''}
            errorMessage={errors.zip && REQUIRED_FIELD}
          />
        </div>
        <div className={styles.listLastItem}>
          <FormInput
            label="Project Address"
            {...register('address', { required: true })}
            placeholder="Enter street name and number"
            defaultValue={
              project?.address?.address ? project?.address.address : ''
            }
            errorMessage={errors.address && REQUIRED_FIELD}
          />
        </div>
      </div>
      <div className={styles.listRow}>
        <div className={styles.listFirstItem}>
          <FormInput
            label="Phone number"
            {...register('phone')}
            placeholder="Enter number you want us to contact at"
            isOptional
            defaultValue={project?.address?.phone ? project?.address.phone : ''}
          />
        </div>
        <div className={styles.listLastItem}>
          <FormInput
            label="Alternative Email"
            {...register('alternativeEmail', {
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: EMAIL_ERROR_MESSAGE,
              },
            })}
            placeholder="Enter alternate email to receive quote at"
            isOptional
            defaultValue={
              project?.alternativeEmail
                ? project?.alternativeEmail
                : ''
            }
            errorMessage={errors?.alternativeEmail?.message}
          />
        </div>
      </div>
      <div className={styles.listRow}>
        <div className={styles.listFirstItem}>
          <FormInput
            label="Specifier"
            {...register('specifier', {
              required: [
                'Electrical Distributor / Purchasing Agent',
                'Rep Agent',
                'Dealer',
              ].includes(authUser?.user_type),
            })}
            placeholder="Enter specifier name"
            isOptional={
              ![
                'Electrical Distributor / Purchasing Agent',
                'Rep Agent',
                'Dealer',
              ].includes(authUser?.user_type)
            }
            defaultValue={project?.specifier || ''}
            errorMessage={errors.specifier && REQUIRED_FIELD}
          />
        </div>
      </div>
      <Button
        type="submit"
        className="type-sans-030 link focus:outline-none w-auto mt-2"
        aria-label="Save Details"
      >
        Save Details
      </Button>
    </form>
  );
};
ProjectEdit.propTypes = {
  projectId: PropTypes.string.isRequired,
  setEditMode: PropTypes.func.isRequired,
  project: PropTypes.shape({
    application_type: PropTypes.string,
    address: PropTypes.object,
  }).isRequired,
};
export default ProjectEdit;
