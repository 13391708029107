const priceFormat = (number) => {
  const currencyFormatted = number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return currencyFormatted.replace(/\.00$/g, '');
};

export default priceFormat;
