import React from 'react';
import PropTypes from 'prop-types';
import { titleCase } from '@helpers/stringFormat';

const SalesRep = ({ reps, repsLoading }) => {
  const dataAttrs = ['name', 'email', 'phone'];
  return (
    <>
      {!repsLoading && reps !== null && reps.length && (
        <div className="print:w-4/5 print:ml-auto">
          <h2 className="type-sans-630 mt-16 mb-6">RBW Sales Rep</h2>
          <ul className="md:flex justify-between print:flex print:flex-wrap print:gap-6">
            {dataAttrs.map((attr) => (
              <li key={attr} className="mb-4 md:mb-0">
                <span className="block text-black type-sans-040">
                  {titleCase(attr)}
                </span>
                <span className="block type-sans-330 text-mono-500">
                  {reps[0][attr]}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

SalesRep.defaultProps = {
  reps: null,
};

SalesRep.propTypes = {
  reps: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.number,
  ]),
  repsLoading: PropTypes.bool.isRequired,
};

export default SalesRep;
