import cn from 'classnames';
import noScroll from 'no-scroll';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Portal } from 'react-portal';
import * as styles from './styles';

const Popup = ({ isOpen, onClose, children, widthClass = '' }) => {
  useEffect(() => {
    if (isOpen) {
      noScroll.on();
    } else {
      noScroll.off();
    }
  }, [isOpen]);

  return (
    <Portal>
      <div
        className={cn(
          'grid place-items-center px-4 py-10 md:px-10 fixed inset-0 z-60 h-full transition-opacity duration-500',
          {
            'opacity-0': !isOpen,
            'opacity-100': isOpen,
            'pointer-events-none': !isOpen,
            'pointer-events-auto': isOpen,
          },
        )}
      >
        <div className={styles.backdrop} onClick={onClose} />
        <div className={cn(widthClass, {[styles.popup_modal]: true})}>{children}</div>
      </div>
    </Portal>
  );
};

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Popup;
