import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './styles';

const FormNumber = React.forwardRef(
  ({ label, errorMessage, name, ...rest }, ref) => {
    return (
      <div className={styles.inputGroup} key={name}>
        <input
          ref={ref}
          id={name}
          name={name}
          type="number"
          min="1"
          className={styles.input}
          {...rest}
        />
        {errorMessage && (
          <span className={styles.inputErrorMessage}>{errorMessage}</span>
        )}
      </div>
    );
  },
);

FormNumber.displayName = 'FormNumber';

FormNumber.defaultProps = {
  label: '',
  errorMessage: '',
};

FormNumber.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

export default FormNumber;
