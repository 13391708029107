import Button from '@components/Button';
import ColumnLayout from '@components/ColumnLayout';
import SEO from '@components/seo';
import AuthUserContext from '@context/AuthUserContext';
import FormError from '@utility/FormError';
import FormHoneypot from '@utility/FormHoneypot';
import FormInput from '@utility/FormInput';
import Link from '@utility/Link';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  EMAIL_ERROR_MESSAGE,
  EMAIL_FIELD_PATTERN,
  REQUIRED_FIELD,
} from '../../../common';
import withFirebase from '../Firebase/withFirebase';

const ResetPassword = ({ firebase }) => {
  const { authUser } = useContext(AuthUserContext);
  const accountData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              _rawPasswordAccount(resolveReferences: { maxDepth: 5 })
            }
          }
        }
      }
    `,
  );
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: authUser && authUser.email ? authUser.email : '',
    },
  });

  const { errors } = formState;

  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const { _rawPasswordAccount } = accountData.allSanityAccount.edges[0].node;

  const onSubmit = (data) => {
    firebase
      .firebasePasswordReset(data.email)
      .then(setConfirmationMessage(true))
      .catch((error) => {
        setSubmitError(error);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
  };

  return (
    <ColumnLayout
      heading={_rawPasswordAccount.heading ? _rawPasswordAccount.heading : ''}
      subheading={
        _rawPasswordAccount.subheading ? _rawPasswordAccount.subheading : ''
      }
      image={_rawPasswordAccount.image && _rawPasswordAccount.image}
      hideSubhead={confirmationMessage}
    >
      <div className="relative">
        <SEO title="Password Reset">
          <meta name="robots" content="noindex" />
        </SEO>
        <div
          className={cn(
            'transition-opacity type-sans-330 duration-200 delay-100 h-0 ease-in opacity-0 md:absolute w-full top-0 pointer-events-none',
            {
              'opacity-100 pointer-events-auto h-auto': confirmationMessage,
            },
          )}
        >
          <p>
            An email has been sent. Check your inbox for a password reset link.
          </p>
          {authUser ? (
            <Link
              to="/account/profile"
              className="btn-default btn-default--max-width btn-black block text-center mt-6"
              aria-label="My Account"
            >
              My Account
            </Link>
          ) : (
            <Link
              to="/account/login"
              className="btn-default btn-default--max-width btn-black block text-center mt-6"
              aria-label="Login"
            >
              Login
            </Link>
          )}
        </div>
        <div
          className={cn('md:absolute w-full top-0', {
            hidden: confirmationMessage,
          })}
        >
          {submitError && submitError.message && (
            <FormError>
              <p>{submitError.message}</p>
            </FormError>
          )}
          {/* "handleSubmit" will validate your FormInputs before invoking "onSubmit" */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              label="Email Address"
              type="text"
              placeholder="Enter your email address"
              {...register('email', {
                required: true,
                pattern: EMAIL_FIELD_PATTERN,
              })}
              errorMessage={
                errors.email &&
                (errors.email.type === 'required'
                  ? REQUIRED_FIELD
                  : EMAIL_ERROR_MESSAGE)
              }
            />
            <FormHoneypot register={register} />
            <Button
              type="submit"
              className={cn('btn-default btn-black btn-lg mt-4', {
                'btn-black-disabled': !formState.isValid,
              })}
              aria-label="Submit"
            >
              Done
            </Button>
          </form>
        </div>
      </div>
    </ColumnLayout>
  );
};

ResetPassword.propTypes = {
  firebase: PropTypes.shape({
    firebasePasswordReset: PropTypes.func,
  }).isRequired,
};

export default withFirebase(ResetPassword);
